/* eslint-disable max-len */
export const description = [
  'Sérigraphie artisanale depuis 2010, toutes nos impressions sont réalisées manuellement et avec le plus grand soin. Nous utilisons des encres à base d’eau et vegan, pour un plus grand respect de l’environnement et de meilleures conditions de travail et tous nos textiles sont certifiés par la norme Oeko-Tex 100. Les t-shirts que nous vous proposons sont certifiés FairWear et Better Cotton. Dans ce sens, nous vous encourageons à choisir du textile en coton biologique et équitable pour vos commandes.',
  'N’hésitez pas à nous contacter par e-mail pour toute question, si vous souhaitez imprimer sur un autre support que ceux proposés et pour commander ! Envoyez votre demande à prints@oldtree.fr',
]

export const subTitle = 'Pour le détail voir la grille tarifaire à la fin de la page'

const SLOW_CLASSIC_ONE_COLOR = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 7.50, forfait: 0 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 6.75, forfait: 0 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 6.0, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 5.5, forfait: 0 },
  },
]

const SLOW_CLASSIC_TWO_COLORS = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 8.50, forfait: 0 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 7.75, forfait: 0 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 7.0, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 6.5, forfait: 0 },
  },
]

const SLOW_CLASSIC_RECTO_VERSO = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 8.75, forfait: 0 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 8.0, forfait: 0 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 7.25, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 6.75, forfait: 0 },
  },
]

const SLOW_BIO_ONE_COLOR = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 8.5, forfait: 0 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 7.75, forfait: 0 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 7.0, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 6.5, forfait: 0 },
  },
]

const SLOW_BIO_TWO_COLORS = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 9.5, forfait: 0 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 8.75, forfait: 0 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 8.0, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 7.5, forfait: 0 },
  },
]

const SLOW_BIO_RECTO_VERSO = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 9.75, forfait: 0 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 9.00, forfait: 0 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 8.25, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 7.75, forfait: 0 },
  },
]

const SPEED_CLASSIC_ONE_COLOR = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 7.50, forfait: 50 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 6.75, forfait: 70 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 6.75, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 6, forfait: 0 },
  },
]

const SPEED_CLASSIC_TWO_COLORS = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 8.50, forfait: 60 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 7.75, forfait: 80 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 7.75, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 7, forfait: 0 },
  },
]

const SPEED_CLASSIC_RECTO_VERSO = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 8.75, forfait: 60 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 8.0, forfait: 80 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 8.0, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 7.25, forfait: 0 },
  },
]

const SPEED_BIO_ONE_COLOR = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 8.5, forfait: 50 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 7.75, forfait: 70 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 7.75, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 7.00, forfait: 0 },
  },
]

const SPEED_BIO_TWO_COLORS = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 9.5, forfait: 60 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 8.75, forfait: 80 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 8.75, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 8.00, forfait: 0 },
  },
]

const SPEED_BIO_RECTO_VERSO = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 9.75, forfait: 60 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 9.00, forfait: 80 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: { unity: 9.00, forfait: 0 },
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: { unity: 8.25, forfait: 0 },
  },
]

const SLOW_CLASSIC = {
  oneColor: SLOW_CLASSIC_ONE_COLOR,
  twoColors: SLOW_CLASSIC_TWO_COLORS,
  rectoVerso: SLOW_CLASSIC_RECTO_VERSO,
}

const SLOW_BIO = {
  oneColor: SLOW_BIO_ONE_COLOR,
  twoColors: SLOW_BIO_TWO_COLORS,
  rectoVerso: SLOW_BIO_RECTO_VERSO,
}

const SLOW_PRICES = {
  // type of material
  classic: SLOW_CLASSIC,
  bio: SLOW_BIO,
}

const SPEED_CLASSIC = {
  oneColor: SPEED_CLASSIC_ONE_COLOR,
  twoColors: SPEED_CLASSIC_TWO_COLORS,
  rectoVerso: SPEED_CLASSIC_RECTO_VERSO,
}

const SPEED_BIO = {
  oneColor: SPEED_BIO_ONE_COLOR,
  twoColors: SPEED_BIO_TWO_COLORS,
  rectoVerso: SPEED_BIO_RECTO_VERSO,
}

const SPEED_PRICES = {
  // type of material
  classic: SPEED_CLASSIC,
  bio: SPEED_BIO,
}

export const PRICES = {
  // Type of delivery
  slow: SLOW_PRICES,
  speed: SPEED_PRICES,
}
