/* eslint-disable max-len */
import React from 'react'

import {
  ListContainer, IncludedList, ListLine,
} from '../Cabs/styles'

import { DonwloadPDF } from '../Tshirt/styles'

const List = () => (
  <ListContainer>
    <IncludedList>
      <ListLine>Le tarif n’inclut pas le prix du papier ni les éventuels frais de port. Merci de nous contacter par mail pour choisir le papier ensemble, en fonction du visuel et du résultat souhaité.</ListLine>
      <ListLine>Tout besoin de modification de visuels par nos soins sera facturé 20€ minimum.</ListLine>
      <ListLine>Le délai moyen de fabrication est de 3 semaines à partir de la réception du paiement total. L’impression en 10 jours maximum est possible selon la quantité travail en cours et nos disponibilités. N’hésitez pas à nous contacter pour tout autre demande !</ListLine>
      <ListLine>
        <DonwloadPDF>Téléchargez les spécifications techniques</DonwloadPDF>
      </ListLine>
    </IncludedList>
  </ListContainer>
)

export default List
