import { giveMeNumber } from '../../helpers'
import { a3Table, a2Table } from './data'

export const genPaperTable = table => table.reduce(
  (acc, { min, max, price }) => {
    const label = `${min} à ${max}`
    const priceLabel = `${price}€/couleur et unité`

    return [...acc, [label, priceLabel]]
  }, [],
)

export const validateCouleurQuantity = couleurStr => {
  if (couleurStr === '') return false

  const cleanQuantity = parseInt(couleurStr, 10)
  if (Number.isNaN(cleanQuantity)) return false

  return cleanQuantity >= 1 && cleanQuantity <= 6
}

export const validateQuantity = (quantity, couleurStr) => {
  if (quantity === '') return { isValid: false, message: null }

  const cleanQuantity = parseInt(quantity, 10)
  if (Number.isNaN(cleanQuantity)) return { isValid: false, message: null }

  if (couleurStr === '') return { isValid: true, message: null }

  const cleanColor = parseInt(couleurStr, 10)
  if (Number.isNaN(cleanColor)) return { isValid: true, message: null }

  if (cleanColor === 3 && cleanQuantity < 40) {
    return {
      isValid: false,
      message: 'Pour 3 couleurs 40 impressions minimum',
    }
  }

  if (cleanColor === 4 && cleanQuantity < 50) {
    return {
      isValid: false,
      message: 'Pour 4 couleurs 50 impressions minimum',
    }
  }

  return {
    isValid: cleanQuantity >= 25 && cleanQuantity <= 300,
    message: null,
  }
}

export const countTotal = (
  format, quantity, color, otherConditionOk = true,
) => {
  if (!format || !quantity || !color || !otherConditionOk) return null

  if ([quantity, color].some(str => giveMeNumber(str) === null)) {
    return null
  }

  const cleanQuantity = parseInt(quantity, 10)
  const cleanColorQuantity = parseInt(color, 10)
  const table = format === 'a3' ? a3Table : a2Table

  const tableItem = table.find(
    ({ min, max }) => cleanQuantity >= min && cleanQuantity <= max,
  )

  if (!tableItem) {
    return null
  }

  const price = tableItem.price * cleanQuantity * cleanColorQuantity
  return price
}

export const genDataForEmailDevi = ({
  quantity, format, color,
}) => {
  const data = [
    {
      label: 'Taille papier',
      value: `jusqu'à ${(format || '').toUpperCase()}`,
    },
    {
      label: 'Quantité',
      value: quantity,
    },
    {
      label: 'Nombre de couleurs',
      value: color,
    },
  ]

  return data
}
