import React from 'react'

import PricePreviw from '../Cabs/PricePreviw'
import List from './List'
import Grille from './Grille'
import Form, { Dialog, genDevis } from '../Form'
import PageImage from '../PageImage'
import StyledLin from '../StyledLink'
import Button from '../Button'
import { Footer } from '../Hardcase/styles'

import {
  Content,
  DevisTitle,
  Section,
  TitleSection,
} from '../Cabs/styles'

import {
  PapersContainer,
  Paper,
  PaperWrapper,
  PaperTextContainer,
  PaperTitle,
  PaperSubtitle,
} from './styles'

import {
  Description, Subtitle, Input, InputLabel, InputContainer, InputErrorMessage,
} from '../Tshirt/styles'

import { description, subTitle } from '../Tshirt/data'

import {
  validateCouleurQuantity,
  validateQuantity,
  countTotal,
  genDataForEmailDevi,
} from './helpers'

class PaperPrint extends React.Component {
  state = {
    format: null, // [a3, a2]
    quantity: '1',
    colours: '1',
    edited: false,
    showForm: false,
  }

  onToggleForm = () => {
    const { showForm } = this.state
    this.setState({ showForm: !showForm })
  }

  onChangeFormat = format => {
    this.setState({ format })
  }

  onChangeQuantity = e => {
    const newQuantity = e.target.value
    if (newQuantity === '') {
      this.setState({ quantity: newQuantity, edited: true })
    } else {
      const cleanNumber = parseInt(newQuantity, 10)
      if (cleanNumber) {
        this.setState({ quantity: `${cleanNumber}`, edited: true })
      }
    }
  }

  onChangeCouleurs = e => {
    const newQuantity = e.target.value
    if (newQuantity === '') {
      this.setState({ colours: newQuantity, edited: true })
    } else {
      const cleanNumber = parseInt(newQuantity, 10)
      if (cleanNumber) {
        this.setState({ colours: `${cleanNumber}`, edited: true })
      }
    }
  }

  render() {
    const {
      format, quantity, colours, edited, showForm,
    } = this.state
    const { isValid, message } = validateQuantity(quantity, colours)
    const isValidQuantity = !edited || isValid

    const isValidCouleur = !edited || validateCouleurQuantity(colours)

    const otherCondition = isValidCouleur && isValidQuantity
    const amount = countTotal(format, quantity, colours, otherCondition)
    const showPrice = !!amount

    const devisDataForMail = genDataForEmailDevi({
      quantity, format, color: colours,
    })
    const devisAsStringForMail = genDevis(
      'Impression papier',
      devisDataForMail,
      amount,
    )

    return (
      <>
        {showPrice && (
          <PricePreviw amount={amount} text={[]} openForm={this.onToggleForm} />
        )}
        <Dialog show={showForm} onClose={this.onToggleForm}>
          <Form
            title="Envoyer le devis"
            emailSubject="Devis Prints"
            withDevis
            devis={devisAsStringForMail}
          />
        </Dialog>
        <PageImage page="paper" />
        <Content>
          {description.map((d, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Description key={i}>{d}</Description>
          ))}
          <DevisTitle>Créez votre devis</DevisTitle>
          <Subtitle>{subTitle}</Subtitle>
          <Section>
            <TitleSection>
              Choisissez la taille
            </TitleSection>
            <PapersContainer>
              <PaperWrapper
                onClick={() => { this.onChangeFormat('a3') }}
                isSelected={format === 'a3'}
              >
                <Paper>
                  <PaperTextContainer>
                    <PaperSubtitle>jusq&#39;à</PaperSubtitle>
                    <PaperTitle>A3</PaperTitle>
                  </PaperTextContainer>
                </Paper>
              </PaperWrapper>
              <PaperWrapper
                onClick={() => { this.onChangeFormat('a2') }}
                isSelected={format === 'a2'}
              >
                <Paper>
                  <PaperTextContainer>
                    <PaperSubtitle>jusq&#39;à</PaperSubtitle>
                    <PaperTitle>A2</PaperTitle>
                  </PaperTextContainer>
                </Paper>
              </PaperWrapper>
            </PapersContainer>
          </Section>
          {
            format && (
              <>
                <Section>
                  <TitleSection>Nombre d&#39;impressions</TitleSection>
                  <InputContainer>
                    <InputLabel
                      error={!isValidQuantity}
                    >
                      Quantité (entre 25 et 300 impressions)
                    </InputLabel>
                    <Input
                      error={!isValidQuantity}
                      type="number"
                      min="1"
                      step="1"
                      value={quantity}
                      onChange={this.onChangeQuantity}
                    />
                    <InputErrorMessage error>
                      {
                        message && !isValidQuantity && (
                          `* ${message}`
                        )
                      }
                    </InputErrorMessage>
                  </InputContainer>
                </Section>
                <Section>
                  <TitleSection>Nombre de couleurs</TitleSection>
                  <InputContainer>
                    <InputLabel
                      error={!isValidCouleur}
                    >
                      Couleurs (entre 1 et 4 couleurs)
                    </InputLabel>
                    <Input
                      error={!isValidCouleur}
                      type="number"
                      min="1"
                      max="4"
                      step="1"
                      value={colours}
                      onChange={this.onChangeCouleurs}
                    />
                  </InputContainer>
                </Section>
                <Section>
                  <TitleSection>Informations supplémentaires</TitleSection>
                  <List />
                </Section>
                <Section>
                  <TitleSection>Grille tarifaire</TitleSection>
                  <Grille />
                </Section>
                <Section>
                  <TitleSection style={{ maxWidth: 300, margin: '0 auto' }}>
                    {/* eslint-disable-next-line max-len */}
                    Pour toute question, si vous souhaitez imprimer sur un autre support que ceux proposés et pour commander :
                  </TitleSection>
                  <Footer>
                    <StyledLin to="/contact">
                      <Button>Contactez-nous</Button>
                    </StyledLin>
                  </Footer>
                </Section>
              </>
            )
          }
        </Content>
      </>
    )
  }
}

export default PaperPrint
