export const formatPrice = price => {
  if (typeof price === 'string') {
    const numericPrice = parseFloat(price)
    return numericPrice.toFixed(2)
  }

  if (typeof price === 'number') {
    return price.toFixed(2)
  }

  console.error('Invalid Price format')
  return price
}

export const giveMeNumber = numberStr => {
  if (numberStr === '') return null

  const cleanNumber = parseFloat(numberStr)
  if (Number.isNaN(cleanNumber)) return null

  return cleanNumber
}
