/* eslint-disable max-len */
import React from 'react'

import PriceTable, { TablesContainer } from '../PriceTable'
import { TablesWrapper } from './styles'

import { a3Table, a2Table } from './data'
import { genPaperTable } from './helpers'

const Grille = () => (
  <TablesWrapper>
    <TablesContainer>
      <PriceTable
        title="jusqu'à A3"
        lines={genPaperTable(a3Table)}
      />
    </TablesContainer>
    <TablesContainer>
      <PriceTable
        title="jusqu'à A2"
        lines={genPaperTable(a2Table)}
      />
    </TablesContainer>
  </TablesWrapper>
)

export default Grille
