export const a3Table = [
  {
    id: '25-99',
    min: 25,
    max: 99,
    price: 2,
  },
  {
    id: '100-200',
    min: 100,
    max: 200,
    price: 1.50,
  },
  {
    id: '201-300',
    min: 201,
    max: 300,
    price: 1,
  },
]

export const a2Table = [
  {
    id: '25-99',
    min: 25,
    max: 99,
    price: 4,
  },
  {
    id: '100-200',
    min: 100,
    max: 200,
    price: 3,
  },
  {
    id: '201-300',
    min: 201,
    max: 300,
    price: 2,
  },
]
